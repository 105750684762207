<template>
    <div>
        <div v-if="isArabic" class="marketo-form ar">
            <Typography class="title" variant="h3-display" as="h3">{{ $t('إشترك اليوم') }}</Typography>
            <Typography class="description" variant="body-display-small" as="p">{{ formDescriptionArabic }}</Typography>
            <form id="mktoForm_1014"></form>
            <div id="confirmFormCTWEngage" style="display: none">
                <strong>شكرا لك</strong>
                <p>
                    الرجاء العودة إلى رسالة في بريدكم الإلكتروني تحوي رابطاً إلى موقع كارنيغي carnegiendowment.org، حيث
                    يمكنكم تعديل موادكم المفضَّلة.
                </p>
            </div>
        </div>
        <div v-else class="marketo-form">
            <Typography class="title" variant="h3-display" as="h3">{{ $t('Subscribe Today') }}</Typography>
            <Typography class="description" variant="body-display-small" as="p">{{
                formDescriptionEnglish
            }}</Typography>
            <form id="mktoForm_1001"></form>
            <div id="confirmFormCTWEngage" style="display: none">
                <strong>Thank you!</strong>
                <p>Check your email for details on your request.</p>
            </div>
        </div>
    </div>
</template>

<script setup>
const props = defineProps({
    formContextData: {
        type: Object,
    },
    formDescriptionArabic: {
        type: String,
        default:
            'سوف تنقل المدوّنة أيضاً ردود فعل الخبراء تجاه الأخبار العاجلة والأحداث الآنيّة، وتشكّل منبراً لبثّ مقابلات تُجرى مع شخصيّات عامّة وسياسية، كما ستسمح بمواكبة الأبحاث الصادرة عن كارنيغي.',
    },
    formDescriptionEnglish: {
        type: String,
        default:
            'Drawing on the expertise of a team of Carnegie scholars—both in the Middle East and in Washington—Diwan offers reactions to breaking news, interviews with personalities and political figures, and updates on Carnegie research projects.',
    },
    scrollOut: {
        type: Boolean,
        default: false
    }
});
const { locale } = useLocale();
const isArabic = computed(() => {
    return locale._value === 'ar';
});
function formDiwanEn() {
    MktoForms2.loadForm('//Pages.carnegieendowment.org', '813-XYU-422', 1001, function (form) {
        // Set values for the hidden fields, "Site_Source__c"
        // Note that these fields were configured in the form editor as hidden fields already
        if (props.scrollOut) {
            form.vals({ Site_Source__c: 'Scrollout Diwan Subscribe Mkto En' });
        }
        else {
            form.vals({ Site_Source__c: 'Diwan Subscribe Mkto En' });
        }
    });
    MktoForms2.whenReady(function (form) {
        //Add an onSubmit to add hidden field values
        form.onSubmit(function (form) {
            form.addHiddenFields({ centerAffiliationAll: 'beirut' });
            form.addHiddenFields({ automatedEmailAll: 'diwanEn' });
        });
        //Add an onSuccess handler
        form.onSuccess(function (values, followUpUrl) {
            //get the form's jQuery element and hide it
            form.getFormElem().hide();
            document.getElementById('confirmFormCTWEngage').style.display = 'block';
            //return false to prevent the submission handler from taking the lead to the follow up url.
            return false;
        });
    });
}
function formDiwanAr() {
    MktoForms2.loadForm('//Pages.carnegieendowment.org', '813-XYU-422', 1014, function (form) {
        // Set values for the hidden fields, "Site_Source__c"
        // Note that these fields were configured in the form editor as hidden fields already
        if (props.scrollOut) {
            form.vals({ Site_Source__c: 'Scrollout Diwan Subscribe Mkto Ar' });
        }
        else {
            form.vals({ Site_Source__c: 'Diwan Subscribe Mkto Ar' });
        }
    });
    MktoForms2.whenReady(function (form) {
        //Add an onSubmit to add hidden field values
        form.onSubmit(function (form) {
            form.addHiddenFields({ centerAffiliationAll: 'beirut' });
            form.addHiddenFields({ automatedEmailAll: 'diwanAr' });
        });
        //Add an onSuccess handler
        form.onSuccess(function (values, followUpUrl) {
            //get the form's jQuery element and hide it
            form.getFormElem().hide();
            document.getElementById('confirmFormCTWEngage').style.display = 'block';
            //return false to prevent the submission handler from taking the lead to the follow up url.
            return false;
        });
    });
}
onMounted(() => {
    (function(){
        function doOrQueueFormsStuff() {
            if (typeof MktoForms2 != "object") {
                document.addEventListener("load", doOrQueueFormsStuff, true);
            } else if (!doOrQueueFormsStuff.done) {
                document.removeEventListener("load", doOrQueueFormsStuff, true);
                doOrQueueFormsStuff.done = true;
                if (locale._value === 'en') {
                    formDiwanEn();
                }
                if (locale._value === 'ar') {
                    formDiwanAr();
                }
            }
        }
        doOrQueueFormsStuff();
    })();
});
</script>

<style lang="scss" scoped>
@import './././assets/styles/core/marketoForms';
</style>
